import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

import { GA_MEASUREMENT_ID } from "src/constants";
import {COOKIE_CONSENT} from 'src/components/CookiesNotification';

const GoogleAnalytics: FC = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {
          Cookies.get(COOKIE_CONSENT) && `
            window.dataLayer = window.dataLayer || [];

            function gtag() {
              dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}');
          `
        }
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
