import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grow,
  makeStyles,
  Typography
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "url('/static/pattern.svg') no-repeat center center",
    minHeight: "400px",
    height: "100vh",
    // backgroundColor: theme.palette.background.dark,
    // padding: "100px",
    [theme.breakpoints.down('md')]: {
      // padding: "60px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "red",
    alignItems: "center",
    gap: "50px"
    // height: "auto"
  },
  logo:{
    width: "40%",
    [theme.breakpoints.down('md')]: {
      width: "80%",
    },
  },
  block:{
    marginLeft: 10,
    width: "100px"
  }
}));

const Hero: FC = ({ }) => {
  const classes = useStyles();
  const [growGsx, setGrowGsx] = React.useState<boolean>(false);
  const [growBlock, setGrowBlock] = React.useState<boolean>(false);
  const [growDesc, setGrowDesc] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      setGrowGsx(true);
    }, 500)
    setTimeout(() => {
      setGrowBlock(true);
    }, 700)
    setTimeout(() => {
      setGrowDesc(true);
    }, 900)
  }, [])

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>


        <Grow in={growGsx} timeout={1000}>
          <img className={classes.logo} src="/static/gsx.svg" />
        </Grow>

        <Grow in={growGsx} timeout={1000}>
          <img className={classes.block} src="/static/gs-block.png" />
        </Grow>

        <Grow in={growGsx} timeout={1000}>
          <Typography color="textPrimary" variant="overline" style={{fontSize: 20}}>BIENTÔT DISPONIBLE</Typography>
        </Grow>

      </Box>
    </Box>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
