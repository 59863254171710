import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

interface CTAProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CTA: FC<CTAProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          color="textPrimary"
        >
          GoldSilver, c'est aussi le compte simple et efficace
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          A partir de 29.90€ / mois
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <img
            alt="gs"
            // className={classes.technologyIcon}
            src="/static/home/business.svg"
          />
        </Box>
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="secondary"
            component="a"
            href="/gs"
            variant="contained"
          >
            Plus d'infos
          </Button>
        </Box>
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
