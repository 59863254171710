import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { APP_VERSION } from 'src/constants';
import Logo from 'src/components/Logo';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 54,
    "vertical-align": "middle",
    "border-radius": "8px",
  },
  brand: {
    height: 20,
    "vertical-align": "middle",
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  contactButton: {
    flexShrink: 0,
    minWidth: 118,
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      // color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink
          to="/"
          >
          <Logo className={classes.logo} />
        </RouterLink>
        {
          <RouterLink
          to="/"
          >
            <img className={classes.brand} src="/static/goldsilver.png" />
          </RouterLink>
        }
        <Box flexGrow={1} />
        {/* <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/docs/pricing"
          underline="none"
          variant="body2"
        >
          Tarifs
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/docs/faq"
          underline="none"
          variant="body2"
        >
          FAQ
        </Link> */}
        <Divider className={classes.divider} />
        <Button
          className={classes.contactButton}
          color="secondary"
          component="a"
          href="mailto:contact@gold-silver.eu"
          variant="contained"
          size="small"
        >
          Contactez-nous
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
