import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  link: {
    "text-decoration": "none",
  }
}));

const Footer: FC = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          component="dl"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              Produits
            </Typography>
            <Box mt={4}>
              <dt>
                <Link
                  className={classes.link}
                  to="/"
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                  >
                    GoldSilver X
                  </Typography>
                </Link>
              </dt>
              <dt>
                <Link
                  className={classes.link}
                  to="/gs"
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                  >
                    Le Compte GoldSilver
                  </Typography>
                </Link>
              </dt>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              Juridique
            </Typography>
            <Box mt={4}>
              <dt>
                <Link
                  className={classes.link}
                  to="/legal-notice"
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                  >
                    Mentions légales
                  </Typography>
                </Link>
              </dt>
            </Box>
          </Grid>
        </Grid>
        <Box my={3}>
          <Divider />
        </Box>
        <Typography variant="body1" color="textSecondary">GoldSilver et GoldSilver X sont des marques déposées de LiteCore UAB, Klaipėda, Taikos pr. 111-74, 94230 Klaipėdos m. sav., Lithuania.</Typography>
        <Box display="flex" mt={3} textAlign="center" width="100%">
          <Typography color="textSecondary">
            © {new Date().getFullYear()} GoldSilver - Tous droits réservés
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
