import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { IntercomProvider } from 'react-use-intercom';

import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';

import { defaultTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';

import { INTERCOM_APP_ID } from 'src/constants';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ThemeProvider theme={defaultTheme}>
        <StylesProvider jss={jss}>
          <Router history={history}>
            <GlobalStyles />
            <ScrollReset />
            <GoogleAnalytics />
            <CookiesNotification />
            {renderRoutes(routes)}
          </Router>
        </StylesProvider>
      </ThemeProvider>
    </IntercomProvider>
  );
};

export default App;
